import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StepRouteEnum } from "@core/enums/step-route.enum";

@Injectable({
  providedIn: 'root',
})
export class StepRouteService {
  #formStatus: boolean = false;

  constructor(private router: Router) {}

  get isHomeScreen(): boolean {
    return this.router.url.endsWith(StepRouteEnum.Home);
  }

  get isContactSettingScreen(): boolean {
    return this.router.url.endsWith(StepRouteEnum.ContactSetting);
  }

  get isPaymentScreen(): boolean {
    return this.router.url.endsWith(StepRouteEnum.Payment);
  }

  get isConfirmationScreen(): boolean {
    return this.router.url.includes(StepRouteEnum.Confirmation);
  }

  get isStepRouteScreen(): boolean {
    return this.isHomeScreen || this.isContactSettingScreen || this.isPaymentScreen || this.isConfirmationScreen;
  }

  set formStatus(status: boolean) {
    this.#formStatus = status;
  }

  get formValid(): string {
    return this.#formStatus ? '' : 'disabled';
  }
}
