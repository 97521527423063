import { Link } from "@shared/models/link";

export const PAYMENT_TERMS_PV: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions',
      href: 'https://www.pierreetvacances.com/fr-fr/mentions-cgv_ms'
    },
  ],
  "in-en": [
    {
      label: 'SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions',
      href: 'https://www.pierreetvacances.com/gb-en/general-terms-use_ms'
    },
  ]
}

export const PAYMENT_TERMS_CP: { [key: string]: Link[] } = {
  "fr-fr": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions",
      href: "https://www.centerparcs.fr/fr-fr/conditions-generales-vente_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.conditions_apply_to_digital_voucher",
      href: "#"
    }
  ],
  "in-en": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions",
      href: "https://www.centerparcs.eu/in-en/general-terms-sale_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.conditions_apply_to_digital_voucher",
      href: "#"
    }
  ],
  "de-de": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions",
      href: "https://www.centerparcs.de/de-de/allgemeine-geschaftsbedingungen_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.conditions_apply_to_digital_voucher",
      href: "#"
    }
  ],
  "nl-nl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions",
      href: "https://www.centerparcs.nl/nl-nl/algemene-verkoopvoorwaarden_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.conditions_apply_to_digital_voucher",
      href: "#"
    }
  ],
  "be-vl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions",
      href: "https://www.centerparcs.be/be-vl/algemene-verkoopvoorwaarden_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.conditions_apply_to_digital_voucher",
      href: "#"
    }
  ],
  "be-wl": [
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.cp_terms_and_conditions",
      href: "https://www.centerparcs.be/be-wl/cgv_ms"
    },
    {
      label: "SCREEN_GIFTVOUCHER_COMMON.conditions_apply_to_digital_voucher",
      href: "#"
    }
  ],
}
