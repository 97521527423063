import { Injectable } from "@angular/core";
import { ThemeService } from "./theme/theme.service";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class GiftVoucherService {

  VOUCHERCAMPAIGN = 'VOUCHERCAMPAIGN';
  CURRENCY = 'CURRENCY';

  VOUCHER_VALUE_KEY = 'GV_VOUCHER_VALUE';
  VOUCHER_QUANTITY_KEY = 'GV_VOUCHER_QUANTITY';
  HAS_RECEIVER_KEY = 'GV_HAS_RECEIVER';

  RECEIVER_NAME_KEY = 'GV_RECEIVER_NAME';
  RECEIVER_EMAIL_KEY = 'GV_RECEIVER_EMAIL';
  RECEIVE_DATE_KEY = 'GV_RECEIVER_DATE';
  MESSAGE_KEY = 'GV_MESSAGE';

  private goToNextStepSubject = new Subject<any>();
  goToNextStep$ = this.goToNextStepSubject.asObservable();

  private goToPreviousStepSubject = new Subject<any>();
  goToPreviousStep$ = this.goToPreviousStepSubject.asObservable();

  constructor(private http: HttpClient, private themeService: ThemeService){}

  get currency(): string {
    const _currency = sessionStorage.getItem(this.CURRENCY);
    return _currency ?? 'EUR';
  }

  set currency(_currency: string) {
    sessionStorage.setItem(this.CURRENCY, _currency);
  }

  get voucherCampaign(): string {
    return sessionStorage.getItem(this.VOUCHERCAMPAIGN);
  }

  set voucherCampaign(_campaign: string) {
    sessionStorage.setItem(this.VOUCHERCAMPAIGN, _campaign);
  }

  set voucherValue(value: number) {
    sessionStorage.setItem(this.VOUCHER_VALUE_KEY, value.toString());
  }

  get voucherValue(): number {
    const _voucherValue = sessionStorage.getItem(this.VOUCHER_VALUE_KEY);
    if (_voucherValue) {
      return Number(_voucherValue);
    }
    return undefined;
  }

  set voucherQuantity(quantity: number) {
    sessionStorage.setItem(this.VOUCHER_QUANTITY_KEY, quantity.toString());
  }

  get voucherQuantity(): number {
    const _quantity = sessionStorage.getItem(this.VOUCHER_QUANTITY_KEY);
    if (_quantity) {
      return Number(_quantity);
    }
    return undefined;
  }

  set hasReceiver(value: boolean) {
    sessionStorage.setItem(this.HAS_RECEIVER_KEY, value.toString());
  }

  get hasReceiver(): boolean {
    const _hasReceiver = sessionStorage.getItem(this.HAS_RECEIVER_KEY);
    if (_hasReceiver) {
      return _hasReceiver === 'true';
    }
    return false;
  }

  set receiverName(name: string) {
    sessionStorage.setItem(this.RECEIVER_NAME_KEY, name);
  }

  get receiverName(): string {
    return sessionStorage.getItem(this.RECEIVER_NAME_KEY);
  }

  set receiveDate(date: Date) {
    sessionStorage.setItem(this.RECEIVE_DATE_KEY, (date ?? new Date()).toString());
  }

  get receiveDate(): Date {
    const _receiveDate = sessionStorage.getItem(this.RECEIVE_DATE_KEY);
    if (_receiveDate) {
      return new Date(_receiveDate);
    }
    return undefined;
  }

  set receiverEmail(email: string) {
    sessionStorage.setItem(this.RECEIVER_EMAIL_KEY, email);
  }

  get receiverEmail(): string {
    return sessionStorage.getItem(this.RECEIVER_EMAIL_KEY);
  }

  set message(value: string) {
    sessionStorage.setItem(this.MESSAGE_KEY, value);
  }

  get message(): string {
    return sessionStorage.getItem(this.MESSAGE_KEY);
  }

  getGiftOptions(): Observable<any> {
    return this.http.get(`${environment.api.giftVoucher}/gifts/${this.themeService.theme.brand.toUpperCase()}`);
  }

  emitGoToNextStep(): void {
    this.goToNextStepSubject.next('next');
  }

  emitGoToPreviousStep(): void {
    this.goToPreviousStepSubject.next('previous');
  }

  clearData(): void {
    sessionStorage.removeItem(this.VOUCHER_VALUE_KEY);
    sessionStorage.removeItem(this.VOUCHER_QUANTITY_KEY);
    sessionStorage.removeItem(this.HAS_RECEIVER_KEY);
    sessionStorage.removeItem(this.RECEIVER_NAME_KEY);
    sessionStorage.removeItem(this.RECEIVER_EMAIL_KEY);
    sessionStorage.removeItem(this.RECEIVE_DATE_KEY);
    sessionStorage.removeItem(this.MESSAGE_KEY);
  }
}
