import { Component, OnInit } from '@angular/core';
import { LayoutService } from './layout.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GiftVoucherService } from '@core/services/gift-voucher.service';
import { LoaderService } from '@core/services/loader.service';
import { ContactService } from '@core/services/contact.service';
import { Theme } from '@core/services/theme/theme.model';
import { ThemeService } from '@core/services/theme/theme.service';
import { StepRouteService } from '@core/services/step-route.service';
import { PaymentService } from '@core/services/payment.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  theme: Theme = null;

  constructor(
    private contactService: ContactService,
    public layoutService: LayoutService,
    private route: ActivatedRoute,
    private userService: UserService,
    public translate: TranslateService,
    private giftVoucherService: GiftVoucherService,
    public loaderService: LoaderService,
    private themeService: ThemeService,
    public stepRouteService: StepRouteService,
    private paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.layoutService.setEmbedMode();
    this.initDefaultLang();
    this.initUserMarketAndLang();
    this.getThemeInfos();

    if (this.paymentService.isPaid && !this.stepRouteService.isConfirmationScreen) {
      sessionStorage.clear();
    }
  }

  getThemeInfos(): void {
    this.theme = this.themeService.theme;
  }

  get voucherValue(): number {
    return this.giftVoucherService.voucherValue;
  }

  get voucherQuantity(): number {
    return this.giftVoucherService.voucherQuantity;
  }

  get hasReceiver(): boolean {
    return this.giftVoucherService.hasReceiver;
  }

  get receiverName(): string {
    return this.giftVoucherService.receiverName;
  }

  get receiveDate(): Date {
    return this.giftVoucherService.receiveDate;
  }

  get receiverEmail(): string {
    return this.giftVoucherService.receiverEmail;
  }

  get buyerName(): string {
    return this.contactService.fullname;
  }

  get buyerFirstname(): string {
    return this.contactService.firstname;
  }

  get buyerLastname(): string {
    return this.contactService.lastname;
  }

  get buyerEmail(): string {
    return this.contactService.email;
  }

  get hasBuyerInfo(): boolean {
    return !!this.buyerFirstname || !!this.buyerLastname || !!this.buyerEmail;
  }

  get locale(): string {
    return this.userService.locale;
  }

  initUserMarketAndLang():void {
    this.route.params.subscribe((params: any) => {
      // const marketLang: string[] = params.market?.split('-');
      if(params.market) {
        this.userService.setUserMarketAndLang(params.market);
      }
    });
  }

  initDefaultLang(): void {
    this.translate.addLangs(['en', 'fr', 'de', 'nl', 'it', 'vl', 'wl']);
    this.translate.setDefaultLang('en');
  }

  goToNextStep(): void {
    this.giftVoucherService.emitGoToNextStep();
  }

  goToPreviousStep(event: Event): void {
    event.preventDefault();
    this.giftVoucherService.emitGoToPreviousStep();
  }
}
