import { CommonModule } from '@angular/common';
import { Component, LOCALE_ID, ViewChild } from '@angular/core';
import { GiftVoucherService } from '@core/services/gift-voucher.service';
import { TranslateModule } from '@ngx-translate/core';
import { BankCardCanvasComponent } from '../bank-card-canvas/bank-card-canvas.component';
import { StepRouteService } from '@core/services/step-route.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { UserService } from '@core/services/user.service';
registerLocaleData(localeFr);

@Component({
  selector: 'app-sticky-bar',
  templateUrl: './sticky-bar.component.html',
  styleUrls: ['./sticky-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, BankCardCanvasComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ]
})
export class StickyBarComponent {
  @ViewChild(BankCardCanvasComponent) bankCardCanvas: BankCardCanvasComponent;

  constructor(
    private giftVoucherService: GiftVoucherService,
    private userService: UserService,
    public stepRouteService: StepRouteService,
  ) {}

  get voucherAmount(): number {
    return (this.giftVoucherService.voucherValue ?? 0)*(this.giftVoucherService.voucherQuantity ?? 1);
  }

  get gvCurrency(): string {
    return this.giftVoucherService.currency ?? 'EUR';
  }

  get locale(): string {
    return this.userService.locale;
  }

  openBankCard(): void {
    this.bankCardCanvas.toggle();
  }

  goToNextStep(): void {
    this.giftVoucherService.emitGoToNextStep();
  }
}
