import { Link } from '@shared/models/link';

// There are 3 links in term text content : /contact-setting
export const TERM_LINKS: Link[] = [
  {
    href: 'https://contactariane.com/',
    label: '',
  },
  {
    href: 'https://www.centerparcs.fr/fr-fr/politique-protection-donnees-cookies_ms',
    label: '',
  },
  {
    href: 'https://www.centerparcs.fr/booking/step?hash=U82Sm4ldBFErmesD88zYjhSBBuabsncdCGLcacv7wYpqV2QmDCmyau3xH8DFQLpsq4rEe3Ai69ULs53QytzQ7ssIQ/PVh4ZdtWBTk5lX0dQvCN/sinRqO4TCkGo0fi37fJ5dxMC/2fvN%2BZPPHjCDa2g83faTF/J8Fg/HyOZhf7r0QIo3WYcBqMhGkqOAefLGUehlp3va%2BrldkbCtbaeMQYeb4CZF6JCXISSAnvjCWa28T%2Bbd%2Bbj24p2ezU2nhwXorLw%2B4%2BLSCmaFWnH/HE11CstAEGrZVMM8E%2B7cb1aiW8BjFF32b9bhzZsvto%2BYLVjR9IAyhyXmQAHOx0LwNg5vgYmxFXFpKVws6pDqDA0ZPDXiSjinp6phOeSzQHVzfDtdjv/71es9oX3p8KcgkB027iFOehmlwdrTruzETLp3bNms1G6wsiSWYLpOkb%2BiQ7inUCjVg5y5hsI8EycrHwAFnN/cu6nc8ICt4GtYy8sz%2Bjdz4KAmNd%2Btieu7RU46Ou/EERxOB5/EWLjZgYHe/O2WRXMJR47Ay38dPH0YRc6hZCmziKVP6lt0L84TgwXXGSksVVzS/z5MiQwGZoMWIfnKUl4Q1hChtBOrLwjjc%2BuquWmBzBuk78OOiDG0jNx%2BBCRB52Izv9EmL4iftGbuE0485VR3eftwztMYdDzxlHn9mksFN2JPdlU5GX0ePnsCpYurpa0Z2zDX4H4A/BGQ2jbH07UJ2lGLw/fnoeyEZdZJOw/D1rCR6oc5jKLV%2BTNSKtwItQN9wV7vdjHRJmmswfqAlkZgNEVOzojPaQ08vd0oG844oJeojxYfI1awFFWOyjaeSUStzSGoJJ1owWtUuFjBZR3zQbNC5AZwRkZYnwDFqyc%3D#',
    label: '',
  },
];
