import { TERM_LINKS } from "./terms/term-link.const";
import { FOOTER_SECURE_PAYMENT_CP, FOOTER_SECURE_PAYMENT_PV, FOOTER_LINKS_CP, FOOTER_LINKS_PV } from "./footer/footer.const";
import { Theme } from "./theme.model";
import { PAYMENT_TERMS_CP, PAYMENT_TERMS_PV } from "./payment/payment-term.const";

export const THEMES: Theme[] = [
  {
    host: [
      'default' // Default theme
    ],
    name: 'theme-pv',
    brand: 'pv',
    brandName: 'Pierre & Vacances',
    brandNameShort: 'P&V',
    logo: 'assets/img/pv/logo-pv.png',
    logoAlt: 'assets/img/pv/logo-pv-alt.png',
    card: '/assets/img/card/pv_bank_card.png',
    smallCard: '/assets/img/card/pv_bank_card_small.png',
    appFavicon: 'assets/img/pv/favicon.png',
    appTitle: 'Pierre & Vacances - Pre-Checkin',
    footerLinks: FOOTER_LINKS_CP,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_PV,
    applauseIcon: '/assets/img/pv/applause.png',
    termLinks: TERM_LINKS,
    paymentTermLinks: {},
  },
  {
    host: [
      // 'localhost',
      'gift-onsite360-dev.groupepvcp.com', // dev
      'gift-onsite360-test.groupepvcp.com', // rct
      'gift.pierreetvacances.com', // prod
      'idee-cadeau.pierreetvacances.com'
    ],
    partialHost: 'pierreetvacances',
    name: 'theme-pv',
    brand: 'pv',
    brandName: 'Pierre & Vacances',
    brandNameShort: 'P&V',
    logo: 'assets/img/pv/logo-pv.png',
    logoAlt: 'assets/img/pv/logo-pv-alt.png',
    logoMobile: 'icon-pv-mobile-logo',
    card: '/assets/img/card/pv_bank_card.png',
    smallCard: '/assets/img/card/pv_bank_card_small.png',
    appFavicon: 'assets/img/pv/favicon.png',
    appTitle: 'Pierre & Vacances - Gift Voucher',
    footerLinks: FOOTER_LINKS_PV,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_PV,
    applauseIcon: '/assets/img/pv/applause.png',
    termLinks: TERM_LINKS,
    paymentTermLinks: PAYMENT_TERMS_PV,
  },
  {
    host: [
      'localhost',
      'cp-gift-onsite360-dev.groupepvcp.com',
      'cp-gift-onsite360-test.groupepvcp.com',
      'gift.centerparcs.com', // prod
      'chequecadeau.centerparcs.fr',
      'geschenkgutsch.centerparcs.de',
      'cadeaubon.centerparcs.nl',
      'cadeaubon.centerparcs.be',
      'chequecadeau.centerparcs.be',
      'giftvoucher.centerparcs.eu',
    ],
    partialHost: 'centerparcs',
    name: 'theme-cp',
    brand: 'cp',
    brandName: 'Center Parcs',
    brandNameShort: 'CP',
    logo: 'assets/img/cp/logo-cp.png',
    logoAlt: 'assets/img/cp/logo-cp.png',
    logoMobile: 'icon-cp-mobile-logo',
    card: '/assets/img/card/cp_bank_card.png',
    smallCard: '/assets/img/card/cp_bank_card_small.png',
    appFavicon: 'assets/img/cp/favicon.png',
    appTitle: 'Center Parcs - Gift Voucher',
    footerLinks: FOOTER_LINKS_CP,
    termLinks: TERM_LINKS,
    footerSecurePaymentIcons: FOOTER_SECURE_PAYMENT_CP,
    applauseIcon: '/assets/img/cp/applause.png',
    paymentTermLinks: PAYMENT_TERMS_CP,
  },
]
